<template>
	<div class="flex-row align-items-center">
		<!-- style="max-width: 1320px !important;" -->
		<CContainer>
			<CRow class="justify-content-center">
				<CCol md="8">
					<CCard class="">
						<CCardBody>
							<img src="img/flow1.jpeg" class="img-responsive" style="width: 100%;" />
						</CCardBody>
					</CCard>
				</CCol>
				<CCol md="4">
					<!-- <CCard class="">
						<CCardBody>
							<CRow>
								<CCol col="9" class="text-left">
									<h3><b>Lacak Permohonan</b></h3>
									<p class="text-muted">Lacak Permohonan yang ada ajukan.</p>
								</CCol>
								<CCol col="3" class="text-left">
									<img src="img/login.png" alt="" class="img-responsive" style="width: 75px; float: right;" />
								</CCol>
							</CRow>
							<input type="text" class="form-control">
						</CCardBody>
					</CCard> -->
					<CCard class="">
						<CCardBody>
							<CForm v-on:submit.prevent="loginProcess">
								<CRow>
									<CCol col="9" class="text-left">
										<h2><b>Login</b></h2>
										<p class="text-muted">Masuk dengan email dan password anda</p>
									</CCol>
									<CCol col="3" class="text-left">
										<img src="img/login.png" alt="" class="img-responsive" style="width: 75px; float: right;" />
									</CCol>
								</CRow>
								<CInput placeholder="Email" id="email" type="text">
									<template #prepend-content><CIcon name="cil-user" /></template>
								</CInput>
								<div role="group" class="form-group">
									<div class="input-group">
										<div class="input-group-prepend">
											<span class="input-group-text">
												<CIcon name="cil-lock-locked" />
											</span>
										</div>
										<input v-if="showPassword" autocomplete="curent-password" id="password" type="text" placeholder="Password" class="form-control" />
										<input v-else autocomplete="curent-password" id="password" type="password" placeholder="Password" class="form-control" />
										<div class="input-group-prepend">
											<span @click="showPassword = !showPassword" class="input-group-text">
												<CIcon name="cil-low-vision" />
											</span>
										</div>
									</div>
								</div>
								<div role="group" class="form-group">
									<div class="input-group">
										<div class="input-group-prepend">
											<span class="input-group-text">
												{{text_1}} + {{text_2}} = 
											</span>
										</div>
										<input id="captcha" type="text" placeholder="Captcha" class="form-control" />
									</div>
								</div>
								<!-- <small>*) Aplikasi dalam tahap perbaikan</small> -->
								<CRow>
									<CCol col="6" class="text-left">
										<CButton color="primary" class="px-4" type="submit">Kirim</CButton>
									</CCol>
									<CCol col="6" class="text-right">
										<router-link :to="{ path: 'lupa-password' }" color="link" class="px-0">Lupa Password</router-link>
									</CCol>
								</CRow>
							</CForm>
						</CCardBody>
					</CCard> 
					<CCard>
						<CCardBody style="padding: 8px 1.25rem;">
							<!-- <b>Informasi Kontak</b> -->
							<img src="img/pvtpp.png" alt="" style="width: 100%;" />
							<p class="mb-1">Email: <a href="mailto:">pvt@pertanian.go.id</a> <br> SMS Center Pusat PVTPP : 081281068805</p>
							<center>
								
							<!-- <router-link :to="{path: '/pusat-bantuan'}" class="btn btn-info btn-sm">
								Pusat Bantuan
							</router-link> -->
							</center>
						</CCardBody>
					</CCard>
					<!-- <CCard color="success" text-color="white" class="text-center py-5 d-md-down-none" body-wrapper>
						<CCardBody>
							<h2>Registrasi</h2>
							<p>
								{{info}}
							</p>
							<router-link :to="{path: 'register'}" class="btn btn-outline-light">
								Registrasi
							</router-link>
						</CCardBody>
					</CCard> -->
				</CCol>
				<CCol md="12">
					<CCard class="">
						<CCardHeader>
							<h2 class="text-center"><b>Informasi & Unduh File</b></h2>
						</CCardHeader>
						<CCardBody style="min-height: 450px">
								<img src="img/bg-download.png" style="position: absolute; opacity: 0.7; right: 15px;" />
								<CTabs>
									<CTab v-for="(lfd_value, lfd_key) in list_file_download" :key="lfd_key" :title="lfd_value.komoditas">
										<div class="row">
											<div class="col-md-6" v-for="(lfdd_value, lfdd_key) in lfd_value.data" :key="lfdd_key">
												<table class="table table-borderless table-sm mb-0" style="width: 100%; position: relative;">
													<tbody>
														<tr>
															<td><label for="">{{lfdd_value.nama}}</label></td>
															<td style="width: 100px;">
																<template >
																	<div v-if="lfdd_value.is_modal_tanaman" class="btn btn-info btn-sm pull-right btn-block" v-on:click="openModalTanaman"><i class="fa fa-eye"></i> Lihat</div>
																	<a
																		v-else
																		class="btn btn-info btn-sm pull-right btn-block"
																		:href="lfdd_value.path"
																		target="_blank"
																	>
																		<i class="fa fa-save"></i> Download
																	</a>
																</template>
															</td>
														</tr>
														
														
													</tbody>
												</table>
											</div>
										</div>
									</CTab>
									
								</CTabs>
							
						</CCardBody>
					</CCard>
				</CCol>
				<CCol md="12">
                    <CCard class="top-radius-15">
                        <CCardHeader class="top-radius-15 bg-success text-white">
                            <h4 class="my-0 text-center"><b>FAQ</b></h4>
                        </CCardHeader>
                        <CCardBody>
                            <div>
                                <CCard class="mb-0 a-black" v-for="(val_tipe_faq, key_tipe_faq) in tipe_faq" v-bind:key="key_tipe_faq" style="color: black"> 
                                    <CButton
                                        block
                                        color="link"
                                        class="text-left shadow-none card-header bg-success text-white"
                                        @click="accordion = accordion === key_tipe_faq ? false : key_tipe_faq"
                                    >
                                        <h5 class="m-0 no-underline">{{key_tipe_faq+1}}. {{val_tipe_faq.tipe_faq_nama}}</h5>
                                    </CButton>
                                    <CCollapse :show="accordion === key_tipe_faq">
                                        <CCardBody>
                                            <ol>
                                                <li v-for="(faq_value, faq_key) in val_tipe_faq.faq" :key="faq_key">
                                                    <router-link :to="{name: 'JawabanFaq', params: { idFaq: faq_value.id }}">
                                                        {{faq_value.faq_pertanyaan}}
                                                    </router-link>
                                                </li>
                                            </ol>
                                        </CCardBody>
                                    </CCollapse>
                                </CCard>
                            </div>
                        </CCardBody>
                    </CCard>
                </CCol>
				<CCol md="12">
					<CCard class="">
						<CCardHeader>
							<h2 class="text-center"><b>Link Terkait</b></h2>
						</CCardHeader>
						<CCardBody>
							<div class="owl-carousel owl-theme">
								<div class="item w-100 h-100">
									<a href="https://www.pertanian.go.id/" class="item-tautan text-decoration-none" target="_blank">
										<div class="card border-0 h-100 rounded-custom shadow-sm">
											<div class="card-body text-center d-flex flex-wrap align-items-center justify-content-center">
												<img data-src="img/logo-kementan.png" class="img-fluid mx-auto owl-lazy" style="max-height: 75px; width: auto; opacity: 1;" src="img/logo-kementan.png" />
											</div>
											<div class="card-footer border-0">
												<h5 class="text-center text-dark mb-0">Kementerian Pertanian</h5>
											</div>
										</div>
									</a>
								</div>
								<div class="item w-100 h-100">
									<a href="https://oss.go.id/portal/" class="item-tautan text-decoration-none" target="_blank">
										<div class="card border-0 h-100 rounded-custom shadow-sm">
											<div class="card-body text-center d-flex flex-wrap align-items-center justify-content-center">
												<img data-src="img/osspng.png" class="img-fluid mx-auto owl-lazy" style="max-height: 75px; width: auto; opacity: 1;" src="img/osspng.png" />
											</div>
											<div class="card-footer border-0">
												<h5 class="text-center text-dark mb-0">OSS Republik Indonesia</h5>
											</div>
										</div>
									</a>
								</div>
								<div class="item w-100 h-100">
									<a href="https://oss.go.id/portal/" class="item-tautan text-decoration-none" target="_blank">
										<div class="card border-0 h-100 rounded-custom shadow-sm">
											<div class="card-body text-center d-flex flex-wrap align-items-center justify-content-center">
												<img data-src="img/osspng.png" class="img-fluid mx-auto owl-lazy" style="max-height: 75px; width: auto; opacity: 1;" src="img/osspng.png" />
											</div>
											<div class="card-footer border-0">
												<h5 class="text-center text-dark mb-0">OSS Republik Indonesia</h5>
											</div>
										</div>
									</a>
								</div>
								<div class="item w-100 h-100">
									<a href="https://simpel.pertanian.go.id/" class="item-tautan text-decoration-none" target="_blank">
										<div class="card border-0 h-100 rounded-custom shadow-sm">
											<div class="card-body text-center d-flex flex-wrap align-items-center justify-content-center">
												<img data-src="img/simpel.jpg" class="img-fluid mx-auto owl-lazy" style="max-height: 75px; width: auto; opacity: 1;" src="img/simpel.jpg" />
											</div>
											<div class="card-footer border-0">
												<h5 class="text-center text-dark mb-0">SIMPEL Pertanian</h5>
											</div>
										</div>
									</a>
								</div>
								<div class="item w-100 h-100">
									<a href="http://pvtpp.setjen.pertanian.go.id/download-category/pvt/" class="item-tautan text-decoration-none" target="_blank">
										<div class="card border-0 h-100 rounded-custom shadow-sm">
											<div class="card-body text-center d-flex flex-wrap align-items-center justify-content-center">
												<img data-src="img/pvtpp.png" class="img-fluid mx-auto owl-lazy" style="max-height: 75px; width: auto; opacity: 1;" src="img/pvtpp.png" />
											</div>
											<div class="card-footer border-0">
												<h5 class="text-center text-dark mb-0">PPVTPP</h5>
											</div>
										</div>
									</a>
								</div>
							</div>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
			<template>
				<div>
					<CModal title="KOMODITAS BINAAN KEPMENTAN 591.1/104 2020" color="btn-primary" :show.sync="modalTanaman" size="xl">
						<div class="row">
                            <div class="col-md-10">
                                <div class="row">
                                    <div class="col-md">
                                        <div class="form-group">
                                            <b for=""><b>Kelompok Jenis Tanaman <span class="text-danger">*</span></b></b>
                                            <v-select :options="list_tanaman" label="tk_nama" v-model="kelompok_selected_tanaman"></v-select>
                                        </div>
                                    </div>
                                    <div class="col-md">
                                        <div class="form-group">
                                            <b for="">Sub Kelompok Jenis Tanaman</b>
                                            <v-select :options="kelompok_selected_tanaman.tanaman_kategori_sub" label="tks_nama" v-model="subkelompok_selected_tanaman"></v-select>
                                        </div>
                                    </div>
                                    <div class="col-md">
                                        <div class="form-group">
                                            <b for="">Cari</b>
                                            <input type="text" class="form-control" id="search-tables" placeholder="Search.." v-model="searchData_tanaman" >
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <br>
                                    <div class="btn btn-sm btn-primary" v-on:click="searchTanaman"><i class="fa fa-filter"></i> Filter</div>
                            </div>
                        </div>
						<div class="">
							<table class="table table-sm table-bordered">
								<thead class="bg-dark text-white">
									<tr>
										<th>No</th>
										<th>Kelompok Tanaman</th>
										<th>Sub Kelompok Tanaman</th>
										<th>Jenis Tanaman</th>
										<th>Nama Latin</th>
									</tr>
								</thead>
								<tbody>
									<template>
										<tr v-if="loadingTable_tanaman">
											<td colspan="7"><i class="fa fa-spin fa-refresh"></i> Loading...</td>
										</tr>
										<tr v-else-if="!loadingTable_tanaman && dataintable_tanaman.length <= 0">
											<td colspan="7"><i class="fa fa-times"></i> Data Tidak Tersedia</td>
										</tr>
										<tr v-for="(row_data_tanaman, key_tanaman_tanaman) in dataintable_tanaman" :key="key_tanaman_tanaman" v-else>
											<td>
												<template>
                                                    <div v-if="key_tanaman_tanaman+1 == 10">
                                                        {{curent_page_tanaman}}0
                                                    </div>
                                                    <div v-else>
                                                        <template>
                                                            <span v-if="curent_page_tanaman-1 != 0">
                                                                {{curent_page_tanaman-1}}{{key_tanaman_tanaman+1}}
                                                            </span>
                                                            <span v-else>
                                                                {{key_tanaman_tanaman+1}}
                                                            </span>
                                                        </template>
                                                    </div>
                                                </template>
											</td>
											<td>{{row_data_tanaman.tanaman_kategori_nama}}</td>
											<td>{{row_data_tanaman.tanaman_kategori_sub_nama}}</td>
											<td>{{row_data_tanaman.t_nama}}</td>
											<td>{{row_data_tanaman.t_nama_latin}}</td>
										</tr>
									</template>
								</tbody>
							</table>
							<nav aria-label="pagination">
							    <ul class="pagination justify-content-start">
							        <!---->
							        <!-- {{ page.active ? 'active' : '' }} -->
							        <li v-for="(page_tanaman, key_page_tanaman) in paging_tanaman" v-bind:class="{ 'active': page_tanaman.active }" :key="key_page_tanaman" class="page-item">
							        	<div v-on:click="toPageTanaman(page_tanaman.url)" class="page-link c-page-link-number"> 
								        	<div v-html="page_tanaman.label"></div>
								        </div>
							        </li>
							    </ul>
							</nav>
							<div class="alert alert-info"><i class="fa fa-info"></i> Data diambil sesuai Kepmentan 591.1/104 2020</div>
						</div>
						<template #footer>
							<CButton @click="modalTanaman = false" color="secondary">Tutup</CButton>
						</template>
					</CModal>
				</div>
			</template>
			<template>
				<div>
					<CModal title="Catatan" color="btn-primary" :show.sync="modalAropt" size="xl">
						<CCol sm="6" class="form-inline p-0">
							<label data-v-1c2db5c2="" class="mr-2">Filter:</label>
							<input type="text" class="form-control" id="search-table" placeholder="Search.." v-on:change="searchAropt" v-model="searchData_aropt" >
						</CCol>
						<div class="">
							<table class="table table-sm table-bordered">
								<thead class="bg-dark text-white">
									<tr>
										<th>No</th>
										<th>Nama Latin</th>
										<th>Nama Umum</th>
										<th>Bentuk Benih</th>
										<th>Negara Asal</th>
									</tr>
								</thead>
								<tbody>
									<template>
										<tr v-if="loadingTable_aropt">
											<td colspan="7"><i class="fa fa-spin fa-refresh"></i> Loading...</td>
										</tr>
										<tr v-for="(row_data_aropt, key_aropt_aropt) in dataintable_aropt" :key="key_aropt_aropt" v-else>
											<td>
												<template>
                                                    <div v-if="key_aropt_aropt+1 == 10">
                                                        {{curent_page_aropt}}0
                                                    </div>
                                                    <div v-else>
                                                        <template>
                                                            <span v-if="curent_page_aropt-1 != 0">
                                                                {{curent_page_aropt-1}}{{key_aropt_aropt+1}}
                                                            </span>
                                                            <span v-else>
                                                                {{key_aropt_aropt+1}}
                                                            </span>
                                                        </template>
                                                    </div>
                                                </template>
											</td>
											<td>{{row_data_aropt.tanaman.t_nama_latin}}</td>
											<td>{{row_data_aropt.tanaman.t_nama}}</td>
											<td>{{row_data_aropt.bentuk_benih.bb_nama}}</td>
											<td>{{row_data_aropt.negara.n_inisial}} - {{row_data_aropt.negara.n_nama}}</td>
										</tr>
									</template>
								</tbody>
							</table>
							<nav aria-label="pagination">
							    <ul class="pagination justify-content-start">
							        <!---->
							        <!-- {{ page.active ? 'active' : '' }} -->
							        <li v-for="(page_aropt, key_page_aropt) in paging_aropt" :key="key_page_aropt" v-bind:class="{ 'active': page_aropt.active }" class="page-item">
							        	<div v-on:click="toPageTanaman(page_aropt.url)" class="page-link c-page-link-number" > 
								        	<div v-html="page_aropt.label"></div>
								        </div>
							        </li>
							    </ul>
							</nav>
						</div>
						<template #footer>
							<CButton @click="modalAropt = false" color="secondary">Tutup</CButton>
						</template>
					</CModal>
				</div>
			</template>
		</CContainer>
	</div>
</template>
<script>
    const null_array = [];
	import "core-js/stable";
	import Vue from "vue";
	export default {
		name: "Login",
		data() {
			return {
        showPassword: false,
				list_tanaman: [],
				modalTanaman: false,
				modalAropt: false,
				info: "Jika anda belum memiliki akun, silahkan melakukan proses registrasi melalui link dibawah",
				dataintable_tanaman: [],
				paging_tanaman: [],
				show_data_to_tanaman: '',
				show_data_from_tanaman: '',
				total_data_tanaman: '',
				curent_page_tanaman: '',
				loadingTable_tanaman: true,
				searchData_tanaman: '',
				kelompok_selected_tanaman: {id: ''},
                subkelompok_selected_tanaman: {id: ''},
				dataintable_aropt: [],
				paging_aropt: [],
				show_data_to_aropt: '',
				show_data_from_aropt: '',
				total_data_aropt: '',
				curent_page_aropt: '',
				loadingTable_aropt: true,
				searchData_aropt: '',
				text_1: Math.floor(Math.random() * 20) + 1,
				text_2: Math.floor(Math.random() * 20) + 1,
				accordion: 0,
                tipe_faq: null_array,
                popup_apli: [],
				popup_keterangan: [],
				popup_apli_value: '',
				popup_keterangan_value: '',
				list_file_download: [],
				
			};
		},
		methods: {
			openModalTanaman: function(){
				let self = this;
				this.searchData_tanaman = '';
				this.modalTanaman = true;
				self.getTanaman();
			},
			openModalAropt: function(){
				let self = this;
				this.searchData_aropt = '';
				this.modalAropt = true;
				self.getAropt();
			},
			redirectDashboard: function () {
				if (this.session.roles == "PEMOHON") {
					this.$router.push({ name: "DashboardPemohon" });
				} else {
					this.$router.push({ name: "Dashboard" });
				}
			},
			loginProcess: function () {
				let self = this;
				if (!$("#email").val() || !$("#password").val()) {
					Swal.fire({
						icon: "error",
						title: "Email / Password Kosong",
						text: "Harap isi email dan password anda untuk masuk kedalam aplikasi.",
					});
					return false;
				}
				var generate = this.text_1 + this.text_2;
				if (generate != $('#captcha').val()) {
					Swal.fire({
						icon: "error",
						title: "Captcha Salah",
						text: "Harap isi captcha dengan benar.",
					});
					return false;
				}
				Swal.fire({
					title: '<i class="fa fa-refresh fa-spin"></i>',
					text: "Loading...",
					showConfirmButton: false,
				});
				axios
					.post(this.apiLink + "api/login", {
						email: $("#email").val(),
						password: $("#password").val(),
					})
					.then((response) => {
						
						if (response.data.data.access_token) {
							var token = response.data.data.access_token;
							Vue.prototype.access_token = token;
							Vue.prototype.session = JSON.stringify(response.data.data.user);
							Vue.prototype.session_nama = response.data.data.user.name;
							Vue.prototype.reload_already = "no";
							localStorage.session = JSON.stringify(response.data.data.user);
							localStorage.session_nama = response.data.data.user.name;
							localStorage.access_token = token;
							localStorage.reload_already = "no";
							localStorage.dateLogin = Date.now();
							let timerInterval;
							Swal.fire({
								icon: "success",
								title: "Berhasil",
								text: "Anda akan diarahkan ke halaman dashboard segera",
								timer: 2000,
								timerProgressBar: true,
								showCancelButton: false,
								showConfirmButton: false,
								didOpen: () => {
									timerInterval = setInterval(() => {
										const content = Swal.getContent();
										if (content) {
											const b = content.querySelector("b");
											if (b) {
												b.textContent = Swal.getTimerLeft();
											}
										}
									}, 100);
								},
								willClose: () => {
									clearInterval(timerInterval);
								},
							}).then((result) => {
								/* Read more about handling dismissals below */
								
								
								
								// return false;
								if (localStorage.session && localStorage.session_nama && localStorage.access_token) {
									if (result.dismiss === Swal.DismissReason.timer) {
										localStorage.roles = response.data.data.user.roles_slug;
										if (response.data.data.user.roles_slug == "PEMOHON") {
											self.$router.push({ name: "DashboardPemohon" });
											localStorage.redirectTo = "DashboardPemohon";
										} else {
											self.$router.push({ name: "Dashboard" });
											localStorage.redirectTo = "Dashboard";
										}
									}
								}
							});
							// alert(this.access_token);
							// setTimeout(this.redirectDashboard(), 1500);
						}
					})
					.catch((error) => {
						var message = error.response.data.data.error;
						if (message == "Session has been used") {
							Swal.fire({
								icon: "error",
								title: "Oops...",
								text: "Akun anda telah login di perangkat lain, silahkan logout terlebih dahulu!",
							});
						}
						else if (message == "Account cant find") {
							Swal.fire({
								icon: "error",
								title: "Oops...",
								text: "Periksa Kembali Email & Password Anda!",
							});
						}
						else if (message == "Something went wrong") {
							Swal.fire({
								icon: "error",
								title: "Oops...",
								text: "Terjadi Kesalahan, Coba lagi nanti",
							});
						}else {
							Swal.fire({
								icon: "error",
								title: "Oops...",
								text: "Akun tidak ditemukan, lakukan registrasi",
							});
						}
					});
			},
			toPageTanaman(url) {
				this.loadingTable_tanaman = true;
				axios.get(url)
				.then((response) => {
					var res_tanaman = response.data;
					var res_tanaman_data = res_tanaman.data[0];
					
					if (res_tanaman.meta.code != "200") {
						Swal.fire({
							icon: 'error',
							title: 'Oops...',
							text: res_tanaman.data.message
						});
					}
					else{
						this.loadingTable_tanaman = false;
						this.dataintable_tanaman = res_tanaman_data.data;
						this.paging_tanaman = res_tanaman_data.links;
						this.show_data_from_tanaman = res_tanaman_data.from;
						this.show_data_to_tanaman = res_tanaman_data.to;
						this.total_data_tanaman = res_tanaman_data.total;
						this.curent_page_tanaman = res_tanaman_data.current_page;
					}
				});
			},
			searchTanaman() {
				this.loadingTable_tanaman = true;
				axios.get(this.apiLink + "api/crud/tanaman", {
					params: {
						search: this.searchData_tanaman,
						tanaman_kategori_id: this.kelompok_selected_tanaman.id,
                        tanaman_kategori_sub_id: this.subkelompok_selected_tanaman.id,
					}
				})
				.then((response) => {
					var res_tanaman = response.data;
					var res_tanaman_data = res_tanaman.data[0];
					
					if (res_tanaman.meta.code != "200") {
						Swal.fire({
							icon: 'error',
							title: 'Oops...',
							text: res_tanaman.data.message
						});
					}
					else{
						this.loadingTable_tanaman = false;
						this.dataintable_tanaman = res_tanaman_data.data;
						this.paging_tanaman = res_tanaman_data.links;
						this.show_data_from_tanaman = res_tanaman_data.from;
						this.show_data_to_tanaman = res_tanaman_data.to;
						this.total_data_tanaman = res_tanaman_data.total;
						this.curent_page_tanaman = res_tanaman_data.current_page;
					}
				});
			},
			getTanaman() {
				this.loadingTable_tanaman = true;
				axios.get(this.apiLink + "api/crud/tanaman")
				.then((response) => {
					var res_tanaman = response.data;
					var res_tanaman_data = res_tanaman.data[0];
					
					if (res_tanaman.meta.code != "200") {
						Swal.fire({
							icon: 'error',
							title: 'Oops...',
							text: res_tanaman.data.message
						});
					}
					else{
						this.loadingTable_tanaman = false;
						this.dataintable_tanaman = res_tanaman_data.data;
						this.paging_tanaman = res_tanaman_data.links;
						this.show_data_from_tanaman = res_tanaman_data.from;
						this.show_data_to_tanaman = res_tanaman_data.to;
						this.total_data_tanaman = res_tanaman_data.total;
						this.curent_page_tanaman = res_tanaman_data.current_page;
					}
				});
			},
			toPageAropt(url) {
				this.loadingTable_aropt = true;
				axios.get(url)
				.then((response) => {
					var res_aropt = response.data;
					var res_aropt_data = res_aropt.data[0];
					
					if (res_aropt.meta.code != "200") {
						Swal.fire({
							icon: 'error',
							title: 'Oops...',
							text: res_aropt.data.message
						});
					}
					else{
						this.loadingTable_aropt = false;
						this.dataintable_aropt = res_aropt_data.data;
						this.paging_aropt = res_aropt_data.links;
						this.show_data_from_aropt = res_aropt_data.from;
						this.show_data_to_aropt = res_aropt_data.to;
						this.total_data_aropt = res_aropt_data.total;
						this.curent_page_aropt = res_aropt_data.current_page;
					}
				});
			},
			searchAropt() {
				this.loadingTable_aropt = true;
				axios.get(this.apiLink + "api/crud/aropt", {
					params: {
						search: this.searchData_aropt,
					}
				})
				.then((response) => {
					var res_aropt = response.data;
					var res_aropt_data = res_aropt.data[0];
					
					if (res_aropt.meta.code != "200") {
						Swal.fire({
							icon: 'error',
							title: 'Oops...',
							text: res_aropt.data.message
						});
					}
					else{
						this.loadingTable_aropt = false;
						this.dataintable_aropt = res_aropt_data.data;
						this.paging_aropt = res_aropt_data.links;
						this.show_data_from_aropt = res_aropt_data.from;
						this.show_data_to_aropt = res_aropt_data.to;
						this.total_data_aropt = res_aropt_data.total;
						this.curent_page_aropt = res_aropt_data.current_page;
					}
				});
			},
			getAropt() {
				this.loadingTable_aropt = true;
				axios.get(this.apiLink + "api/crud/aropt")
				.then((response) => {
					var res_aropt = response.data;
					var res_aropt_data = res_aropt.data[0];
					
					if (res_aropt.meta.code != "200") {
						Swal.fire({
							icon: 'error',
							title: 'Oops...',
							text: res_aropt.data.message
						});
					}
					else{
						this.loadingTable_aropt = false;
						this.dataintable_aropt = res_aropt_data.data;
						this.paging_aropt = res_aropt_data.links;
						this.show_data_from_aropt = res_aropt_data.from;
						this.show_data_to_aropt = res_aropt_data.to;
						this.total_data_aropt = res_aropt_data.total;
						this.curent_page_aropt = res_aropt_data.current_page;
					}
				});
			},
		},
		beforeCreate() {
			if (this.reload_already == "yes") {
				Vue.prototype.reload_already = "";
				localStorage.reload_already = "";
				window.location.reload();
			}
		},
		mounted() {
			
			$(".owl-carousel").owlCarousel({
				loop: true,
				margin: 10,
				nav: true,
				responsive: {
					0: {
						items: 1,
					},
					600: {
						items: 2,
					},
					1000: {
						items: 3,
					},
				},
			});
			
			if(localStorage.redirectTo){
				router.push({ name: localStorage.redirectTo });
					// this.$router.push({ name: localStorage.redirectTo });
			}
		},
        created() {
        	let self = this;
            axios.get(this.apiLink + "api/master/tipe_faq", {
                headers: {
                    // Authorization: "Bearer " + this.access_token,
                },
            })
            .then((response) => {
                var res_tipe_faq = response.data;
                var res_tipe_faq_data = res_tipe_faq.data;
                if (res_tipe_faq.meta.code != "200") {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: res_aropt.data.message
                    });
                }
                else{
                    this.tipe_faq = res_tipe_faq_data.master;
                }
            });
            axios.get(this.apiLink + "api/master/tanaman")
            .then((response) => {
                var res_tanaman = response.data;
                var res_tanaman_data = res_tanaman.data;
                if (res_tanaman.meta.code != "200") {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: res_tanaman.data.message
                    });
                }
                else{
                    this.list_tanaman = res_tanaman_data.master;
                    
                }
            });
			axios.get(this.apiLink + "api/master/list_file_download")
            .then((response) => {
                var res_file_download = response.data;
                var res_file_download_data = res_file_download.data;
                if (res_file_download.meta.code != "200") {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: res_file_download.data.message
                    });
                }
                else{
                    this.list_file_download = res_file_download_data.master;
                    console.log(res_file_download_data.master);
                }
            });
        	axios.get(this.apiLink + "api/crud/konfigurasi")
			.then((response) => {
				var res_konfigurasi = response.data;
				var res_konfigurasi_data = res_konfigurasi.data[0];
				
				if (res_konfigurasi.meta.code != "200") {
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: res_konfigurasi.data.message
					});
				}
				else{
					this.popup_apli = res_konfigurasi_data.find(options => {
						if (options.id === 2) {
							return options
						}
					});
					this.popup_keterangan = res_konfigurasi_data.find(options => {
						if (options.id === 3) {
							return options
						}
					});
					this.popup_apli_value = this.popup_apli.k_aksi;
					this.popup_keterangan_value = this.popup_keterangan.k_aksi;
					if (this.popup_apli_value == 'open') {

						Swal.fire({
							icon: "info",
							title: "Pemberitahuan",
							html: self.popup_keterangan_value,
						});
					}
				}
			});
        }
	};
</script>